import * as R from "ramda";
import { HANDLE_UPDATE_REFERRALS } from "./actionsTypes";

const initialState = {
  referrals: [],
  cardRewards: null,
  tradingRewards: null,
};

const updateReferrals = (state, { response }) => {
  const data = response.data;
  return R.merge(state, {
    referrals: data,
    cardRewards: response.card_rewards,
    tradingRewards: response.trading_rewards,
  });
};

const mgmReducer = (state = initialState, action) => {
  switch (action.type) {
    case HANDLE_UPDATE_REFERRALS:
      return updateReferrals(state, action);
    case "REDUX_STORAGE_LOAD":
      return R.merge(state, {
        referrals: [],
        cardRewards: null,
        tradingRewards: null,
      });
    default:
      return state;
  }
};

export default mgmReducer;
