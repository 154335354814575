import { connect } from 'react-redux';
import { compose, lifecycle, withState } from 'recompose';
import { fetchExtract } from '../../redux/actions';



const mapStateToProps = state => ({
  extract: state.user.extract.extracts,
  hasFetchedCurrencies: state.currencies.hasFetchedCurrencies
});

const mapDispatchToProps = (dispatch) => ({
  fetchExtractData: () => {
    const formRequest = {
      currency: '!,BRL',
      status:
        "PENDING,COMPLETE,WAITING_2FA,ERROR,BLOCKED, WAITING_OTP, WAITING_FACEMATCH, WAITING_LIVENESS,EXPIRED",
      type: "DEPOSIT,WITHDRAWAL,DEBIT,CREDIT",
    };
    dispatch(fetchExtract(formRequest))}
});

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withState("hasFetchedExtract", "setHasFetchedExtract", false),
  lifecycle({
    componentDidMount() {
      this.props.fetchExtractData();
      if (this.props.hasFetchedCurrencies) {
        this.props.setHasFetchedExtract(true);
      }
    },
    componentWillReceiveProps(nextProps) {
      const {
        hasFetchedCurrencies,
        hasFetchedExtract,
        setHasFetchedExtract,
      } = nextProps;
      if (hasFetchedCurrencies && !hasFetchedExtract) {
        this.props.fetchExtractData();
        setHasFetchedExtract(true);
      }
    },
  })
);

export default enhance;
