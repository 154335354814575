import React, { useEffect, useState } from "react";
import { compose } from "recompose";
import { Typography, withStyles, Grid } from "@material-ui/core";
import FieldRow from "../../../components/Forms/FieldRow";
import LimitsIndicator from "../../../components/LimitsIndicator";
import Select from "../../../components/FormsV2/Select";
import SelectItemChip from "../../../components/SelectItemChip";
import Form from "../../../components/Forms/Form";
import { styles } from "../style";
import { USDT } from "../../../config";
import InfoTooltip from "../../../components/InfoTooltip";
import { getCurrencyBySymbol } from "../../../models/Currency";

import { FIREBASE_CURRENCIES_ISSUES } from '../../../config'
import { getCurrencyIssuesFoundBySymbolAndScreen } from '../../../utils/firebaseUtils'
import { CurrencyIssue } from '../../../models/CurrencyIssue'
import LimitPresentComponent from "./components/LimitPresentComponent";
import MissingLimitComponent from "./components/MissingLimitComponent";
import InsufficientKycComponent from "./components/InsufficientKycComponent";

const LimitStatusComponent = ({ available_used, kycLevel, props }) => {
  if (kycLevel < 2) {
    return <InsufficientKycComponent {...props} />;
  }
  
  if (available_used <= 0 && kycLevel >= 2) {
    return <MissingLimitComponent {...props} />;
  }

  return <LimitPresentComponent {...props} />;
};

const SendCoinsForm = (props) => {
  const {
    handleSubmit,
    onSubmit,
    t,
    currency,
    limits,
    classes,
    isOnlyBlockchainBep20,
    isOnlyBlockchainErc20,
    blockchain,
    kycLevel,
    handleBlockchainChange,
    blockchainOptions,
    selectedMarket,
    currencies,
    setAnchorEl,
    anchorEl,
  } = props;
  
  const { available_used } = limits
  const [currenciesIssuesFound, setCurrenciesIssuesFound] = useState();

  const coinSelected = selectedMarket.split("-")[0];
  const selectedCurrency =
    getCurrencyBySymbol(currencies, coinSelected) ||
    getCurrencyBySymbol(currencies, "BTC");

  const coinsSelectorExtraData = {
    hasStableCoin: true,
    stableCoinAction: "withdrawal",
  }
  const { firebaseCurrenciesIssues } = FIREBASE_CURRENCIES_ISSUES;

  useEffect(() => {
    if (firebaseCurrenciesIssues) {
      const currenciesIssuesData = getCurrencyIssuesFoundBySymbolAndScreen(
        firebaseCurrenciesIssues, 
        coinSelected, 
        'WITHDRAWAL'
      );
      const currenciesIssues = 
        currenciesIssuesData && 
        currenciesIssuesData.map(CurrencyIssue.fromJson);
      setCurrenciesIssuesFound(currenciesIssues);
    }
  }, [firebaseCurrenciesIssues]);

  return (
    <React.Fragment>
      <Form {...{ handleSubmit, onSubmit }}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Typography variant="body2">
            {t("transactions.chooseCurrency")}
          </Typography>
        </div>
        <Select
          value={selectedCurrency}
          customHandleClick={(e) =>
            anchorEl
              ? setAnchorEl(null)
              : setAnchorEl(e.currentTarget, true, coinsSelectorExtraData)
          }
          placeholder={selectedCurrency.symbol}
          getLabel={(option) => {
            return (
              <SelectItemChip
                itemName={option.symbol}
                currency={option}
                itemCode={option}
              />
            );
          }}
        />

        <React.Fragment>
          <div>
            <Typography
              style={{
                alignSelf: "center",
                marginTop: 10,
              }}
              variant="body2"
            >
              {t("transactions.networkBlockchain")}
            </Typography>
          </div>
          <Select
            value={blockchain}
            options={blockchainOptions}
            placeholder={t("forms.deposit.select_bank")}
            onChange={handleBlockchainChange}
            getLabel={(option) => {
              if (option.label) return option.label;
              const networkDetails = blockchainOptions.find(
                (networkObject) => networkObject.value === option
              );
              return networkDetails ? networkDetails.label : "";
            }}
          />
        </React.Fragment>

        {currenciesIssuesFound &&
          currenciesIssuesFound.map((currencyIssue, index) => (
            <Grid
              key={`currencyIssue-${index}`}
              container
              xs={12}
              justify="center"
              alignItems="center"
              className={classes.currencyIssueWithdrawal}
            >
              <Typography variant="body2">{currencyIssue.title}</Typography>
              <InfoTooltip
                wrapperRootHeader={true}
                title={currencyIssue.description.join(" ")}
              />
            </Grid>
          ))}

        <LimitStatusComponent
          available_used={available_used}
          kycLevel={kycLevel}
          props={props}
        />
        
        <FieldRow>
          <LimitsIndicator
            type="withdrawal"
            limits={limits}
            currency={currency}
          />
        </FieldRow>
        <FieldRow>
          <div>
            <Typography className={classes.informationStyle} variant="body2">
              Atenção:
            </Typography>
            <Typography
              className={classes.informationStyle}
              variant="body2"
            >{`• ${t("info.blockPossibility")}`}</Typography>
            <Typography
              className={classes.informationStyle}
              variant="body2"
            >{`• ${t("info.limitCycle")}`}</Typography>
            {currency === USDT && (
              <Typography
                className={classes.informationStyle}
                variant="body2"
              >{`• ${t("info.sendUsdtWarning")}`}</Typography>
            )}
            {isOnlyBlockchainBep20 && (
              <Typography
                className={classes.informationStyle}
                variant="body2"
              >{`• ${t("info.sendOnlyBep20Warning", {
                currency,
              })}`}</Typography>
            )}
            {isOnlyBlockchainErc20 && (
              <Typography
                className={classes.informationStyle}
                variant="body2"
              >{`• ${t("info.sendOnlyErc20Warning", {
                currency,
              })}`}</Typography>
            )}
          </div>
        </FieldRow>
      </Form>
    </React.Fragment>
  );
};

export default compose(withStyles(styles))(SendCoinsForm);
