import * as R from 'ramda'
import moment from 'moment'
import { Currency } from '../../../models/Currency';
import { checkIsRobotAccount } from '../../../utils/accountUtils';
import {
  TRANSFER_CURRENCY_TYPES,
  TRANSFER_TYPES,
  TRANSFER_FIAT_WITHDRAWAL_OPTIONS,
  CBRL_EXCHANGE_OPTIONS, CRIPTO,
  TRANSFER_CRIPTO_OPTIONS
} from './constants';
import isNilOrEmpty from '../../../utils/isNilOrEmpty';

export const currencyWithDefault = R.propOr('BRL', 'currency')

export const getLimitAndBalanceFromProps = (props, currencyConfig) => {
  const {
    transferType,
    limits,
    balance,
    blockchain,
    values: {
      destination: { bankName },
    },
  } = props;
  const currencyObject = new Currency(currencyConfig)
  const network = currencyObject.symbol === "BRL" ? bankName && bankName.split("-").length > 1 ? bankName.split("-")[1].trim() : bankName: blockchain;
  const withdrawalInfo = currencyObject.getWithdrawalInfoByNetwork(network)
  const minAmount = withdrawalInfo ? withdrawalInfo.minAmount : 0;


  switch (transferType.toUpperCase()) {
    case TRANSFER_TYPES.DEPOSIT: {
      return {
        limitForOperation: {
          total: limits[currencyObject.symbol].total_deposit,
          used: limits[currencyObject.symbol].used_deposit,
          total_annual: limits[currencyObject.symbol].annual_total_deposit,
          used_annual: limits[currencyObject.symbol].annual_used_deposit,
          available_used: limits[currencyObject.symbol].available_deposit,
          available_annual_used: limits[currencyObject.symbol].annual_available_deposit
        },
        currentBalance: balance['BRL']
      }
    }
    case TRANSFER_TYPES.WITHDRAWAL:
      return {
        limitForOperation: {
          total: limits[currencyObject.symbol].total_withdrawal,
          used: limits[currencyObject.symbol].used_withdrawal,
          total_annual: limits[currencyObject.symbol].annual_total_withdrawal,
          used_annual: limits[currencyObject.symbol].annual_used_withdrawal,
          minWithdrawal: minAmount,
          available_used: limits[currencyObject.symbol].available_withdrawal,
          available_annual_used: limits[currencyObject.symbol].annual_available_withdrawal,
        },
        currentBalance: balance["BRL"],
      };
  }
}

export const shouldResetForm = (values, sourceOptions) => {
  if(values.destination && values.destination.bankName ){
    const { bankName } = values.destination;
    const option = sourceOptions.find(option => option.bankName === bankName);
    return !option;
  }
  return false;
}

const addRegisteredBankOption = props => R.append({
  isRegisteredBank: true,
  bankName: 'Banco Cadastrado - TED',
  address: props.userBankAccount.address

})

const addCurrencyBRL = R.merge({ currency: 'BRL' })
const addCurrencyToBankList = R.map(addCurrencyBRL)

const getWithdrawalFiatSourceOptions = props => {
  return R.pipe(
    addRegisteredBankOption(props),
    addCurrencyToBankList
  )(TRANSFER_FIAT_WITHDRAWAL_OPTIONS)
}

const getWithdrawalSourceOptions = props => {
  switch (props.currencyType) {
    case TRANSFER_CURRENCY_TYPES.FIAT:
      return getWithdrawalFiatSourceOptions(props)
    case TRANSFER_CURRENCY_TYPES.CRIPTO:
      return props.criptoList
  }
}

const getDepositSourceOptions = props => {
  switch (props.currencyType) {
    case TRANSFER_CURRENCY_TYPES.FIAT:
      return addCurrencyToBankList(props.bankList)
    case TRANSFER_CURRENCY_TYPES.CRIPTO:
      return props.criptoList
  }
}

export const getSourceOptionsFromProps = props => {
  const { transferType } = props
  switch (transferType.toUpperCase()) {
    case TRANSFER_TYPES.WITHDRAWAL:
      return getWithdrawalSourceOptions(props)
    case TRANSFER_TYPES.DEPOSIT:
      return getDepositSourceOptions(props)
  }
}

const getCurrencyWithdrawalFixed = (currency, fees, network) => {
  if (currency in fees){
    const withdrawalFixed = fees[currency]["withdrawal_fixed"];
    if (withdrawalFixed){
      if (network in withdrawalFixed)
        return withdrawalFixed[network];
      return currency === "BRL" ? withdrawalFixed["TED"] : {};
    }
  }
  return {};
}

export const getFeesFromProps = (props, currencyConfig) => {
  if (!currencyConfig) return
  const currencyObject = new Currency(currencyConfig)
  const { transferType, blockchain, values: { amount, destination : { bankName } }} = props;
  if (transferType.toUpperCase() === TRANSFER_TYPES.DEPOSIT) return
  const network = currencyObject.symbol === "BRL" ? bankName && bankName.split("-").length > 1 ? bankName.split("-")[1].trim() : bankName: blockchain;
  const withdrawalInfo = currencyObject.getWithdrawalInfoByNetwork(network)
  if (isNilOrEmpty(withdrawalInfo)) return {};
  const percent = withdrawalInfo.percentFee
  const withdrawalFixed = withdrawalInfo.fixedFees.toJson()
  const fixed = withdrawalFixed ? withdrawalFixed.high : 0;
  const totalFees = percent / 100 * Number(amount) + fixed

  return { percent, fixed, totalFees }
}

export const getDialogWithdrawalType = selectedBank=>{
  const timeToNotify = moment(16, 'HH');
  const now = moment();
  const isAfter16 = now.isSameOrAfter(timeToNotify);
  const registeredBankWarning =  isAfter16 ? 'bankWithdrawalWarning' : 'bankWithdrawalSameTitularityWarning';
  return CBRL_EXCHANGE_OPTIONS.includes(selectedBank) ?
    'fiatCBRLWithdrawalWarning' :
    registeredBankWarning;
};

export const getDialogDepositType = selectedBank => {
  return selectedBank.includes('PIX') ? 'depositPixWarning' : 'bankDepositWarning';
}

const changeStableOption = (props)=>{
  const { setFieldValue,
    setCurrencyType } = props;
  setCurrencyType(CRIPTO);
  const destination = TRANSFER_CRIPTO_OPTIONS
    .find(stableCoin=>{
      return stableCoin.bankName === 'CryptoBRL'});
  setFieldValue('destination', destination)
};

const dialogConfigByBank = (selectedBank, props) => {
  const { user } = props;
  const isRobotAccount = user && checkIsRobotAccount(user.tax_id);
  return {
  C_BRL_BANK :
    {
      componentProps: {bank : selectedBank},
      availableChoices: [
        {
          label: 'common.to_withdrawal',
          actionToTake: 'changeToCriptoBRL',
          color: 'secondary',
          variant: 'raised'
        }
      ],
      actionsToTake: {
        changeToCriptoBRL: () => changeStableOption(props)
      }
  },
  NORMAL_BANK :
    {
      componentProps: {
        isRobotAccount: isRobotAccount,
      },
      availableChoices: [
        {
          label: 'common.understood',
          actionToTake: '',
          color: 'secondary',
          variant: 'raised'
        }
      ],
      actionsToTake : {}
  }
}};


export const getDialogStablecoinOptions = (depositLimit, currency) =>{
  return {
    componentProps: { depositLimit,
      currency },
    availableChoices: [
      {
        label: 'common.understood',
        actionToTake: '',
        color: 'secondary',
        variant: 'raised'
      }
    ],
  };
};

export const getDialogOptions = (selectedBank, props) =>{

    return CBRL_EXCHANGE_OPTIONS.includes(selectedBank) ?
      dialogConfigByBank( selectedBank, props).C_BRL_BANK :
      dialogConfigByBank( selectedBank, props).NORMAL_BANK
};
