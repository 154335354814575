import React from 'react';
import * as R from 'ramda';
import { compose, withState } from 'recompose';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/core/styles';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Paper from '@material-ui/core/Paper';
import grey from '@material-ui/core/colors/grey';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import AccountCircleOutline from '@material-ui/icons/AccountCircleOutlined';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import VolumeMuteIcon from '@material-ui/icons/VolumeMute';
import Button from '@material-ui/core/Button';

import { translate } from 'react-i18next';

import { handleAppBarMenu, muteAppVolume, enableAppVolume } from '../../redux/actions';
import AppBarMenuItem from './AppBarMenuItem';
import {getAppBarMenuItems} from '../appBarMenuItems';

import InfoTooltip from '../../components/InfoTooltip';

const styles = theme => ({
  root: {
    position: 'relative'
  },
  icon: {
    color: '#bdbdbd'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  paper: {
    backgroundColor: theme.drawerBackgroundColor,
    color: theme.palette.grey[400],
    position: 'absolute',
    top: 55,
    right: 0,
    left: 0
  },
  listItemText: {
    color: theme.palette.grey[400]
  },
  fake: {
    backgroundColor: grey[200],
    height: theme.spacing.unit,
    margin: theme.spacing.unit * 2,
    // Selects every two elements among any group of siblings.
    '&:nth-child(2n)': {
      marginRight: theme.spacing.unit * 3
    }
  }
});

const AppBarMenuItems = props => {
  const { demoAccount } = props;
  return R.values(
    getAppBarMenuItems(demoAccount).map(({ primary, ...appBarMenuItem }) => (
      <AppBarMenuItem key={primary} primary={primary} {...props} {...appBarMenuItem} />
    ))
  );
}

const EMPTY_SPACE = ' ';
const FIRST_NAME = 0;

const formatUserName = (userName, translator, demoAccount) => {
  try{
    if(!userName && demoAccount){
      return translator('common.demoAccountUserName')
    }
    else if(!userName && !demoAccount){
      return translator('common.emptyUserName')
    }
    const name = userName.split(EMPTY_SPACE);
    if(name.length > 1)
      return `${name[FIRST_NAME]} ${name[name.length-1]}`;
    return userName;
  }
  catch (e) {
    return userName;
  }
}

const AppBarMenu = props => {
  const { name, classes, open, setIsOpen, hasSound, enableSound, muteSound, demoAccount, t } = props;

  const toggleSound = soundEnabled => {
    if (soundEnabled) {
      muteSound();
    } else {
      enableSound();
    }
  };
  return (
    <div className={classes.root}>
      <div className={classes.row}>
        <ClickAwayListener onClickAway={() => setIsOpen(false)}>
          <div>
            <List dense>
              <ListItem button onClick={() => setIsOpen(!open)}>
                <ListItemIcon classes={{ root: classes.listItemText }}>
                  <AccountCircleOutline />
                </ListItemIcon>
                <ListItemText
                  data-cy={"profile-name"}
                  primary={formatUserName(name, t, demoAccount)}
                  classes={{ primary: classes.listItemText }}
                />
              </ListItem>
            </List>
            {open ? (
              <Paper className={classes.paper}>
                <List dense>
                  <AppBarMenuItems demoAccount={demoAccount} setIsOpen={setIsOpen} />
                </List>
              </Paper>
            ) : null}
          </div>
        </ClickAwayListener>
        <Button className={classes.icon} onClick={() => toggleSound(hasSound)}>
          <div className={classes.row}>
            {/*wrapperRootHeader to behave as expected when parent is display:flex */}
            <InfoTooltip title={t('info.soundInfo')} wrapperRootHeader={true}/>
            {hasSound ?    <VolumeUpIcon  />  :  <VolumeMuteIcon />  }
          </div>
        </Button>
      </div>
    </div>
  );
};

const mapStateToProps = ({
  layout: {
    appBar,
    appBar: { hasSound },
  },
  credentials: { demoAccount }
}) => ({
  appBar,
  hasSound,
  demoAccount
});

const mapDispatchToProps = dispatch => ({
  handleAppBarMenu: anchorEl => dispatch(handleAppBarMenu(anchorEl)),
  muteSound: () => dispatch(muteAppVolume()),
  enableSound: () => dispatch(enableAppVolume())
});

const enhance = compose(
  withStyles(styles),
  withState('open', 'setIsOpen', false),
  translate(),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
);

export default enhance(AppBarMenu);
