import React, { useEffect, useState } from "react";
import { Typography } from "@material-ui/core";
import StoreLinks from "../../../../components/Buttons/StoreLinks";
import { getWindowSize } from "../../../../utils/getWindowSize";

const InsufficientKycComponent = props => {
  const { t, classes } = props;

  const [width, setWidth] = useState(getWindowSize().width);
  const flexDirection = width <= 426 ? "column" : "row";

  useEffect(() => {
    const handleResize = () => {
      setWidth(getWindowSize().width);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  return (
    <React.Fragment>
      <div className={classes.noticeWithdrawalCryptoContainer}>
        <Typography className={classes.mainNoticePrhase} variant="body2">
          {t("transactions.insufficientKyc.accountNotVerified")}
        </Typography>
        <Typography
          className={classes.descriptionWithdrawNotice}
          variant="body2"
        >
          {t("transactions.insufficientKyc.increaseLimit")}
          <span className={classes.stylizedDescriptionWithdrawal}>
            {t("transactions.insufficientKyc.accessBitybank")}
          </span>
        </Typography>
        <Typography className={classes.alreadyValidated} variant="body2">
          {t("transactions.insufficientKyc.alreadyValidatedAccount")}
        </Typography>
        <StoreLinks 
          flexDirection={flexDirection}
        />
      </div>
    </React.Fragment>
  );
};

export default InsufficientKycComponent;