import React from 'react';
import { FormControlLabel, IconButton, Radio, Typography, withStyles } from '@material-ui/core';
import FieldRow from '../../../../components/Forms/FieldRow';
import TextField from '../../../../components/Forms/TextField';
import { Currency, getCurrencyBySymbol } from '../../../../models/Currency';
import CriptoAmountField from '../../../../components/Forms/CriptoAmountField';
import classNames from 'classnames';
import MessageCard from '../../../../components/Cards/MessageCard';
import { fixScientificNotation } from '../../../../utils/NumberFormat/roundValues';
import InfoTooltip from '../../../../components/InfoTooltip';
import { CRIPTO_WITHDRAWAL_FEE_URL } from '../../../../utils/constants';
import { LaunchOutlined } from '@material-ui/icons';
import PriorityFeeChip from '../../../../components/PriorityFeeChip';
import RadioGroupField from '../../../../components/Fields/RadioGroupField';
import SubmitButton from '../../../../components/Forms/SubmitButton';
import FeesIndicator from '../../../../components/FeesIndicator';
import BalanceAvailableAfterTransaction from '../../../../components/BalanceAvailableAfterTransaction';


const getAddressLabel = (props) => {
  const {isOnlyBlockchainBep20, isOnlyBlockchainErc20, isOnlyBlockchainSol, isOnlyBlockchainAda, isOnlyBlockchainLuna, isOnlyBlockchainLunc} = props;

  if (isOnlyBlockchainBep20) return "forms.walletAddressBep20";
  else if (isOnlyBlockchainErc20) return "forms.walletAddressErc20";
  else if (isOnlyBlockchainSol) return "forms.walletAddressSolana";
  else if (isOnlyBlockchainAda) return "forms.walletAddressAda";
  else if (isOnlyBlockchainLuna) return "forms.walletAddressLuna";
  else if (isOnlyBlockchainLunc) return "forms.walletAddressLuna";  
  else return "forms.walletAddress";
};

const RadioButtonFee = (data) => {
  const {value, checked, onChange, baseCurrencySymbol,  priorityFee, priorityFeeBrl, currencyData, classes } = data;

  return (
    <FormControlLabel
    value={value}
    control={
      <Radio
      checked={checked}
      onChange={onChange}
        icon={
          <PriorityFeeChip
            baseCurrencySymbol={baseCurrencySymbol}
            active={false}
            priorityType={value}
            priorityFee={priorityFee}
            priorityFeeBrl={priorityFeeBrl}
            currencyData={currencyData}
          />
        }
        checkedIcon={
          <PriorityFeeChip
            baseCurrencySymbol={baseCurrencySymbol}
            active={true}
            priorityType={value}
            priorityFee={priorityFee}
            priorityFeeBrl={priorityFeeBrl}
            currencyData={currencyData}
          />
        }
        className={classes}
      />
    }
    label={""}
  />
  )
}

const LimitPresentComponent = props => {
  const {
    t,
    currency,
    fees,
    limits,
    invalid,
    pristine,
    submitting,
    balance,
    valuesFromState,
    classes,
    freeNetworkTransaction,
    priorityFeesBrl,
    priorityFees,
    handleLowPriorityFeeSelected,
    blockchain,
    selectedMarket,
    currencies,
    hasSameFees,
  } = props;

  const coinSelected = selectedMarket.split("-")[0];
  const selectedCurrency =
      getCurrencyBySymbol(currencies, coinSelected) ||
      getCurrencyBySymbol(currencies, "BTC");
  
  const currencyObj = new Currency(selectedCurrency);
  const withdrawalInfo = currencyObj.getWithdrawalInfoByNetwork(blockchain);
  
  const { minWithdrawal } = limits;
  const minAmountWarning = t("transactions.sendCoins.minAmountToWithdraw", {
    blockchain: blockchain,
    minAmount: fixScientificNotation(minWithdrawal),
  });

  const hasPriorityFee = priorityFees && Object.keys(priorityFees).length > 0;

  const brlCurrencyData = getCurrencyBySymbol(currencies, "BRL");

  const butonLabel = t(`transactions.transfer.action`);

  const priorityTypes = ["high", "medium", "low"];

  return (
    <React.Fragment>
      <FieldRow>
        <TextField
          name="address"
          i18nScope={getAddressLabel(props)}
        />
      </FieldRow>

      {withdrawalInfo && withdrawalInfo.memoEnabled && (
        <FieldRow>
          <TextField name="memo" i18nScope="forms.memoAddress" />
        </FieldRow>
      )}

      <FieldRow>
        <CriptoAmountField
          {...props}
          classes={{}}
          name="amount"
          withFees
          fees={fees}
          currency={currency}
          canUseCriptoBalance
          useCriptoBalanceButtonTitle={t("transactions.useBalance")}
        />
      </FieldRow>

      {blockchain && blockchain !== "" && (
        <div className={classNames(classes.singlePriorityContainer, classes.singlePriorityControlLabel)}>
          <MessageCard
            type="info"
            content={minAmountWarning}
            classes={{
              card: classes.card,
              icon: classes.icon,
              content: classes.content,
              messageCardContentContainer: classes.messageCardContentContainer,
            }}
          />
        </div>
      )}

      {hasPriorityFee && !hasSameFees && (
        <FieldRow>
          <div className={classes.priorityFeeLabel}>
            <Typography
              className={classes.priorityFeeLabelItem}
              variant="body2"
            >
              <div style={{ display: "-webkit-inline-box" }}>
                {t("transactions.priorityFee")}:
                <InfoTooltip title={t("transactions.WarningFee")} />
              </div>
            </Typography>
            <IconButton
              href={CRIPTO_WITHDRAWAL_FEE_URL}
              classes={{ root: classes.knowMoreLink }}
              target={"_blank"}
            >
              <LaunchOutlined scale={"primary"} />
            </IconButton>
            {freeNetworkTransaction && (
              <Typography
                variant="body2"
                className={classes.priorityFeeLabelItem}
              >
                {t("transactions.priorityLabels.freeTransaction")}
              </Typography>
            )}
          </div>
        </FieldRow>
      )}

      {hasPriorityFee && (
        <div>
          {!freeNetworkTransaction && (
            <RadioGroupField
              name="priority"
              className={classes.radioGroupPriority}
            >
                {!hasSameFees && (
                  priorityTypes.map((priorityType, index) => (
                    <RadioButtonFee key={`radio-button-${index}`} value={priorityType} baseCurrencySymbol={selectedCurrency.symbol} priorityFee={priorityFees[priorityType]} priorityFeeBrl={priorityFeesBrl[priorityType]} currencyData={brlCurrencyData} classes={classes.priorityRadio} onChange={priorityType === "low" ? handleLowPriorityFeeSelected : null} />
                  ))
                )}
            </RadioGroupField>
          )}
        </div>
      )}
      <FieldRow>
        <SubmitButton
          submitText={butonLabel}
          color="secondary"
          disabled={submitting || invalid || pristine}
        />
      </FieldRow>

      {valuesFromState.amount >= minWithdrawal && (
        <FieldRow>
          <FeesIndicator
            balance={balance}
            amount={valuesFromState.amount}
            fees={fees}
            type="sendCoins"
            currency={selectedCurrency}
          />
        </FieldRow>
      )}
       <FieldRow>
          <BalanceAvailableAfterTransaction
            amount={valuesFromState.amount}
            balance={balance}
            fees={fees}
            currency={selectedCurrency}
            t={t}
          />
        </FieldRow>
    </React.Fragment>
  )
}

export default LimitPresentComponent;