export const styles = (theme) => ({
    card: {
      padding: 10,
    },
    icon: {
      fontSize: 25,
    },
    iconContainer: {
      [theme.breakpoints.down("xs")]: {
        display: "none",
      }
    },
    content: {
      [theme.breakpoints.down("xs")]: {
        fontSize: 12,
        textAlign: "center",
      },
    },
    messageCardContentContainer: {
      [theme.breakpoints.down("xs")]: {
        justifyContent: "center",
      },
    },
    knowMoreLink: {
      width: "24px",
      height: "24px",
    },
    singlePriorityContainer: {
      width: "100% !important",
      maxWidth: "400px",
    },
    singlePriorityControlLabel: {
      margin: "0 0 10px",
    },
    noticeWithdrawalCryptoContainer: {
      width: "95%",
      display: "flex",
      flexDirection: "column",
      gap: "8px",
      alignItems: "center",
      backgroundColor: "#F2F2F2",
      borderRadius: "25px",
      padding: "10px 10px 20px",
      margin: "40px",
    },
    mainNoticePrhase: {
      color: "#FF6800",
      fontWeight: "bold",
      fontSize: "14px",
      textAlign: "center",
    },
    alreadyValidated: {
      color: "#808080",
      fontWeight: "medium",
      fontSize: "10px",
      textAlign: "center",
    },
    stylizedDescriptionWithdrawal: {
      color: "#FDA11E",
    },
    stylizedNoticeButton: {
      borderRadius: "40px",
      fontSize: "13px",
      maxWidth: "152px",
      width: "100%",
      height: "46px",
    },
    descriptionWithdrawNotice: {
      maxWidth: "430px",
      width: "100%",
      textAlign: "center",
      lineHeight: "15px",
      fontSize: "12px",
    },
    radioGroup: {
      display: "flex",
      flexDirection: "row",
      marginTop: "10px",
      marginBottom: 32,
      marginRight: 10,
      marginLeft: 10,
      justifyContent: "center",
      flexWrap: "wrap",
    },
    radioItem: {
      width: "40%",
      marginLeft: "3%",
      marginRight: 0,
    },
    radioGroupBlockchain: {
      display: "flex",
      flexDirection: "row",
      marginBottom: 32,
      justifyContent: "center",
      flexWrap: "nowrap",
    },
    radioGroupPriority: {
      display: "flex",
      flexDirection: "row",
      marginBottom: 32,
      marginLeft: 50,
      justifyContent: "center",
      flexWrap: "nowrap",
    },
    radioItemPriority: {
      width: "80%",
      marginLeft: "3%",
      marginRight: 0,
    },
    priorityRadio: { width: "80%", height: "60%", margin: "3px" },
    priorityRadioSingle: { width: "100%", height: "60%", margin: "3px 0" },
    priorityFeeLabel: {
      display: "flex",
      alignItems: "center",
    },
    priorityFeeLabelItem: {
      alignSelf: "center",
      color: "rgba(0, 0, 0, 0.54)",
      margin: 3,
    },
    bep20Label: {
      [theme.breakpoints.down("sm")]: {
        display: "flex",
      },
      display: "-webkit-inline-box",
    },
    informationStyle: {
      marginBottom: 10,
      alignSelf: "center",
      color: "rgba(0, 0, 0, 0.54)",
    },
    unavailableFeatureStyle: {
      margin: 10,
      alignSelf: "center",
      color: "#212121",
      fontWeight: 500,
    },
    currencyIssueWithdrawal: {
      margin: "0.5rem auto 0.125rem",
    }
  });