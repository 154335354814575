import red from '@material-ui/core/colors/red'
import green from '@material-ui/core/colors/green'

export const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  containerEditBank: {
    display: "block",
    width: "100%",
  },
  radioGroupEditBank: {
    display: "flex",
    flexDirection: "row",
    marginTop: "10px",
    justifyContent: "center",
  },
  cardField: {
    paddingLeft: 24,
    paddingRight: 24,
  },
  bankInfoContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  avatarSending: {
    backgroundColor: red[500],
  },
  avatarReceiving: {
    backgroundColor: green[500],
  },
  centerRowItem: {
    alignItems: "center",
  },
  infoPixLimit: {
    marginTop: 1,
  },
  editButton: {
    backgroundColor: theme.palette.secondary.main,
    "&:hover": {
      backgroundColor: theme.palette.secondary.dark,
    },
  },
  marginBottomDefault: {
    marginBottom: 16,
  }
});
