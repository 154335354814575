import React from 'react';
import { Button, Typography } from '@material-ui/core';
import { SUPPORT_FAQ_CENTER_URL } from '../../../../utils/constants';

const MissingLimitComponent = props => {
  const { t, classes } = props;
  
  return (
  <React.Fragment>
    <div className={classes.noticeWithdrawalCryptoContainer}>
      <Typography className={classes.mainNoticePrhase} variant='body2'>
        {t("transactions.missingLimit.withoutLimit")}
      </Typography>
      <Typography className={classes.descriptionWithdrawNotice} variant='body2'>
        {t("transactions.missingLimit.increaseLimit")}
        <span className={classes.stylizedDescriptionWithdrawal}>{t("transactions.missingLimit.contactSupport")}</span>
      </Typography>
      <Button
        color="secondary"
        variant="contained"
        className={classes.stylizedNoticeButton}
        href={SUPPORT_FAQ_CENTER_URL}
        target="_blank"
      >
        {t("transactions.missingLimit.labelButton")}
      </Button>
    </div>
  </React.Fragment>
)};


export default MissingLimitComponent;